import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {PaymentsService} from "../../services/payments.service";
import {environment} from "../../../../../bringit_pay/src/environments/environment";

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

  paidMsg!: string;
  errMsg!: string;

  data = {
    business_id: '',
    token: '',
    credit_token: '',
  };

  constructor(private activatedRoute:ActivatedRoute,
              private paymentsService: PaymentsService) {


    this.activatedRoute.params.subscribe((params: Params) => {
      this.data.token = params['order_token']
      this.data.business_id = params['business_id']

    })
    this.activatedRoute.queryParams.subscribe(params => {
      this.data.credit_token = params['txId']
    })

    this.paymentsService.orderPaid(this.data)
      .subscribe(
        () => {
          this.paidMsg = "בוצע תשלום בהצלחה, תכף תועבר לדף מעקב אחר הזמנה";
        },
        (err) => {
          this.paidMsg = (err.error?.message || err.message);
        });
  }

  ngOnInit(): void {
  }

}
